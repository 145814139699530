import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { signIn, signOut, useSession } from "next-auth/client";
import { useDispatchStore, useStore } from "../store";
import axios from "axios";

export default function Page() {
  const [session, loading] = useSession();
  const router = useRouter();
  const dispatch = useDispatchStore();

  useEffect(() => {
    if (!session) {
      router.push("/auth/login");
    } else {
      router.push("/admin/workitems");
    }
  }, [session]);

  const fetchTags = async () => {
    try {
      const { data } = await axios({ method: "GET", url: "/api/tags" });
      dispatch({ type: "TAGS", payload: data });
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        router.push("/auth/login");
      }
    }
  };
  useEffect(() => {
    fetchTags();
  }, []);

  return <></>;
}
